import ReactECharts from "echarts-for-react";
import { toFixed } from "../../../utils/utils";
import { useStockDetails } from "../../../hooks/query/useStockDetails";
// const iconPath = process.env.PUBLIC_URL + '/logos';

const ReportPieChart = ({
    center,
    title,
    shares = {},
    gain = {},
    type,
    revert,
}: {
    center?: number | null;
    title: string;
    shares?: any;
    gain?: any;
    type: "Equity" | "Percent";
    revert?: boolean;
}) => {
    const tickers = Object.keys(shares) || [];
    const { useStockDetailsQuery } = useStockDetails();
    const stockDetailsQuery = useStockDetailsQuery(tickers);
    // const sum = Object.keys(shares).reduce((acc, ticker) => acc + shares[ticker], 0);
    // const subtext = type === "Equity" ? `Total ${toFixed(sum)} USD`: `${toFixed(sum * 100)}% of portfolio`;

    if (stockDetailsQuery.isLoading) {
        return <div>Loading...</div>;
    }

    const options = {
        tooltip: {
            trigger: "item",
            formatter: (params: any) => {
                const companyGain = toFixed(
                    gain[params.data.name] * 100 || 0,
                    2
                );
                const isPositive = companyGain > 0;
                const arrow = isPositive ? "▲" : "▼";
                const gainColor = isPositive ? "green" : "red";
                const company = stockDetailsQuery.data?.find(
                    (stock) => stock.symbol === params.data.name
                );
                // const logo = company?.logo ? `<img src="${company.logo}" />` : "";
                const logo = "";
                const name = company?.name || "";
                const ticker = company?.name
                    ? `(${params.data.name})`
                    : params.data.name;

                const mainPart = `${logo} ${name} ${ticker}`;
                const gainPart =
                    companyGain !== 0
                        ? `<span style="display:inline-block;margin-right:4px;color:${gainColor}">${arrow}</span> ${isPositive ? "+" : "-"} ${Math.abs(companyGain)}%`
                        : "";

                return `${mainPart}<br/>${gainPart}`;
            },
        },
        legend: {
            bottom: 0,
        },
        series: [
            {
                name: "Portion of Portfolio",
                type: "pie",
                radius: ["40%", "70%"],

                data: Object.keys(shares).map((ticker) => ({
                    value:
                        type === "Equity"
                            ? revert
                                ? toFixed(shares[ticker]) * -1
                                : toFixed(shares[ticker])
                            : toFixed(shares[ticker] * 100),
                    name: ticker,
                    label: {
                        color: "#000",
                        fontSize: "20",
                        position: "center",
                        formatter: () => {
                            return center ? `${toFixed(center * 100)}%` : "";
                        },
                    },
                })),
            },
        ],
    };
    return (
        <div>
            <h4>{title}</h4>
            <ReactECharts option={options} notMerge={true} />
        </div>
    );
};

export default ReportPieChart;
