import Stack from "react-bootstrap/Stack";
import { Apps } from "./Apps";
import { ReactComponent as CCFRIcon } from "../../../assets/images/ANP_logo_col.svg";
import { Link, NavLink, useParams } from "react-router-dom";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useModels } from "../../../hooks/query/useModel";

const Menu = () => {
    let { modelId } = useParams();
    const { useModelsQuery } = useModels();
    const modelQuery = useModelsQuery();
    return (
        <Stack direction="horizontal" gap={3}>
            <div className="p-2">
                <Link to="/">
                    <CCFRIcon
                        style={{
                            height: "3rem",
                        }}
                    />
                </Link>
            </div>
            <div className="ms-auto p-2">
                <Dropdown as={ButtonGroup} className="px-1">
                    {modelId ? (
                        <NavLink to={`/${modelId}`}>
                            <Button variant="primary">Portfolio Details</Button>
                        </NavLink>
                    ) : (
                        <Button disabled variant="primary">
                            Portfolio Details
                        </Button>
                    )}

                    <Dropdown.Toggle
                        split
                        variant="primary"
                        id="dropdown-split-basic"
                    />

                    <Dropdown.Menu>
                        {(modelQuery.data || []).map((model) => (
                            <Dropdown.Item
                                disabled={!model.strategyId}
                                key={model.modelId}
                                href={`/${model.modelId}`}
                            >
                                {model.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                {modelId ? (
                    <NavLink to={`/strategy/${modelId}`} className="px-1">
                        <Button variant="primary">Edit</Button>
                    </NavLink>
                ) : (
                    <span className="px-1">
                        <Button disabled variant="primary">
                            Edit Portfolio
                        </Button>
                    </span>
                )}

                {modelId ? (
                    <NavLink to={`/model/${modelId}`} className="px-1">
                        <Button variant="primary">Choose Factor</Button>
                    </NavLink>
                ) : (
                    <span className="px-1">
                        <Button disabled variant="primary" className="px-1">
                            Choose Factor
                        </Button>
                    </span>
                )}

                <Apps />
            </div>
            <div className="p-2">{/* <User /> */}</div>
        </Stack>
    );
};

export default Menu;
