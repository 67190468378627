import { OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { MdAddChart } from "react-icons/md";
import { MdMultilineChart } from "react-icons/md";
import { MdTableChart } from "react-icons/md";
import { BsCashStack } from "react-icons/bs";
import { LuSigma } from "react-icons/lu";
import { MdStackedBarChart } from "react-icons/md";
import { Link } from "react-router-dom";
import { getANPHost } from "../../../utils/envHandler";

export const Apps = () => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Analytical Platform</Popover.Header>
            <Popover.Body>
                <Stack>
                    <Stack
                        direction="horizontal"
                        gap={3}
                        style={{
                            alignItems: "baseline",
                        }}
                    >
                        <div className="p-2 app">
                            <MdAddChart className="app-icon" size="2rem" />
                            <Link to={`${getANPHost()}/stockPickingLab`}>
                                <div className="app-name">
                                    Stock Picking Lab
                                </div>
                            </Link>
                        </div>
                        <div className="p-2 app">
                            <MdMultilineChart
                                className="app-icon"
                                size="2rem"
                            />
                            <Link to={`${getANPHost()}/patternLab`}>
                                <div className="app-name">Pattern Lab</div>
                            </Link>
                        </div>
                        <div className="p-2 app">
                            <MdTableChart className="app-icon" size="2rem" />
                            <Link
                                to={`${getANPHost()}/optimization-fe/portfolioManager`}
                            >
                                <div className="app-name">
                                    Portfolio Manager
                                </div>
                            </Link>
                        </div>
                    </Stack>
                    <Stack
                        direction="horizontal"
                        gap={3}
                        style={{
                            alignItems: "baseline",
                        }}
                    >
                        <div className="p-2 app">
                            <BsCashStack className="app-icon" size="2rem" />
                            <Link to={`${getANPHost()}/taxes`}>
                                <div className="app-name">Taxes</div>
                            </Link>
                        </div>
                        <div className="p-2 app">
                            <MdStackedBarChart
                                className="app-icon"
                                size="2rem"
                            />
                            <Link to={"/"}>
                                <div className="app-name">Factor Investing</div>
                            </Link>
                        </div>
                        <div className="p-2 app">
                            <LuSigma className="app-icon" size="2rem" />
                            <Link
                                to={`${getANPHost()}/optimization-fe/marketSentiment`}
                            >
                                <div className="app-name">Market Sentiment</div>
                            </Link>
                        </div>
                    </Stack>
                </Stack>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <span>
                <BsFillGrid3X3GapFill size={"2em"} />
            </span>
        </OverlayTrigger>
    );
};
