import AuthClientStore from "../../../api/authClientStore";
import { ApiMethods, RequestParams } from "../useApi";
import { get, post, patch, del } from "aws-amplify/api";

const API_NAME = "stockpicker";

const apiAWS: ApiMethods = {
    async getCall<T>(url: string, params?: RequestParams) {
        const request = get({
            apiName: API_NAME,
            path: url,
            options: {
                headers: {
                    ...params?.headers,
                    Authorization: `Bearer ${AuthClientStore.getAccessToken()}`,
                },
            },
        });

        const response = await request.response;
        const data = await response.body.json();
        return {
            status: response.statusCode,
            data: data as T,
        };
    },
    async postCall<T>(url: string, params?: any) {
        const request = post({
            apiName: "stockpicker",
            path: url,
            options: {
                headers: {
                    ...params?.headers,
                    Authorization: `Bearer ${AuthClientStore.getAccessToken()}`,
                },
                body: params?.body,
            },
        });
        const response = await request.response;
        return response as T;
    },
    async putCall(url: string, params?: RequestParams) {
        console.log("PUT CALL - NOT YET IMPLEMENTED");
        return new Promise(() => {});
    },
    async deleteCall<T>(url: string, params?: RequestParams) {
        const request = del({
            apiName: API_NAME,
            path: url,
            options: {
                headers: {
                    ...params?.headers,
                    Authorization: `Bearer ${AuthClientStore.getAccessToken()}`,
                },
            },
        });
        const response = await request.response;
        return {
            status: response.statusCode,
            data: null as T,
        };
    },
    async patchCall<T>(url: string, params?: RequestParams) {
        const request = patch({
            apiName: API_NAME,
            path: url,
            options: {
                headers: {
                    ...params?.headers,
                    Authorization: `Bearer ${AuthClientStore.getAccessToken()}`,
                },
                body: params?.body,
            },
        });
        const response = await request.response;
        const data = await response.body.json();
        return {
            status: response.statusCode,
            data: data as T,
        };
    },
};

export default apiAWS;
